import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ThemeContext from '../context/theme-context'
import Blog from '../components/blog'
import BlogHero from '../components/blog/heroBanner'
import PostDescription from '../components/blog/postDescription'
import InsightText from '../components/blog/insightText'

import { graphql } from 'gatsby'

class IndexPage extends React.Component {
    componentDidMount() {
        const { closeMenu } = this.context
        closeMenu()
    }

    render() {
        const { isMobile, darkMode, currentLocale, intl } = this.context

        const { codeandsoulInsights } = this.props.data

        return (
            <Layout showNavLinks={true} initialMenuToggle={true}>
                <SEO title="Blog" />
                <BlogHero data={codeandsoulInsights} />
                <PostDescription data={codeandsoulInsights} />
                <InsightText data={codeandsoulInsights} />
                <Blog currentLocale={currentLocale} isMobile={isMobile} />
            </Layout>
        )
    }
}

IndexPage.contextType = ThemeContext

export default IndexPage

export const query = graphql`
    query InsightPageQuery($slug: String) {
        codeandsoulInsights(slug: { eq: $slug }) {
            author
            banner
            body
            description
            id
            origin
            post_date
            slug
            title
            remoteImage {
                childImageSharp {
                    fluid(maxWidth: 670, maxHeight: 820) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`
