import React from 'react';

import Img from 'gatsby-image';

const HeroBanner = ({ data: { title, remoteImage } }) => {
    return (
        <section
            className="hero-banner-insights hero-banner-post"
            datanav="white"
        >
            <div className="container">
                <div className="wrap-blog-hero">
                    <div className="wrap-hero-content">
                        <h2
                            className="hero-title"
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                        <div className="wrap-image">
                            <Img
                                className="hero-image"
                                fluid={remoteImage.childImageSharp.fluid}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroBanner;
