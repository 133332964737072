import React from 'react'

const PostDescription = ({
    data: { origin, post_date, author, description },
}) => {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    const postDate = new Date(post_date)

    return (
        <section className="wrap-green-section">
            <div className="container">
                <div className="wrap-post-info">
                    <span className="insight-author">
                        From <strong>{origin}</strong>,
                        {` ${postDate.getFullYear()} ${
                            monthNames[postDate.getMonth()]
                        }`}
                        <br />
                        {author}
                    </span>
                </div>

                <div className="post-description">
                    <p
                        className="insight-description"
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
            </div>
        </section>
    )
}

export default PostDescription
