import React from 'react'

const InsightText = ({ data: { body } }) => {
    return (
        <article className="insight-text">
            <div className="container">
                <div
                    className="wrap-post-text"
                    dangerouslySetInnerHTML={{ __html: body }}
                />
            </div>
        </article>
    )
}

export default InsightText
